import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SortableTable from "../../components/sortable-table"
import VotingRecordTableHeader from "../../components/voting-record-table-header"
import { getAssemblyVotes } from "../../data/voting-record"

const rows = getAssemblyVotes()

export default function StateAssemblyVotes() {
  function render(row, field) {
    const vote = row[field]

    if (vote === "No") {
      return <span className="green-pill">{vote}</span>
    }

    if (vote === "Aye") {
      return <span className="red-pill">{vote}</span>
    }

    if (vote === "NVR") {
      return <span className="blue-pill">{vote}</span>
    }
  }

  function renderAb787(row, field) {
    const vote = row[field]

    if (vote === "No") {
      return <span className="red-pill">{vote}</span>
    }

    if (vote === "Aye") {
      return <span className="green-pill">{vote}</span>
    }

    if (vote === "NVR") {
      return <span className="blue-pill">{vote}</span>
    }
  }

  const columns = [
    {
      field: "Surname",
    },
    {
      field: "District",
    },
    {
      field: "Party",
    },
    {
      field: "SB9",
      render,
    },
    {
      field: "SB10",
      render,
    },
    {
      field: "AB68",
      render,
    },
    {
      field: "AB215",
      render,
    },
    {
      field: "AB787",
      render: renderAb787,
    },
    {
      field: "AB1398",
      render,
    },
    {
      field: "SB8",
      render,
    },
    {
      field: "SB290",
      render,
    },
    {
      field: "SB477",
      render,
    },
    {
      field: "SB478",
      render,
    },
  ]

  return (
    <Layout>
      <SEO title="2021 State Assembly Votes" />
      <h1>2021 State Assembly Votes</h1>
      <p>
        California Cities for Local Control lobbied on the following bills.
        Check out your State Assembly member's voting record below. (Votes that
        aligned with our positions are identified in green. NVR means a vote was
        not cast.){" "}
      </p>
      <SortableTable
        className="w-full"
        rows={rows}
        columns={columns}
        defaultSort="Surname:asc"
        beforeTableHeader={() => {
          return <VotingRecordTableHeader />
        }}
      />
    </Layout>
  )
}
